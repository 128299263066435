.mybutton {
    background: #131d29;
    background-image: -webkit-linear-gradient(top, #0462CD, #1E62D0);
    background-image: -moz-linear-gradient(top, #0462CD, #1E62D0);
    background-image: -ms-linear-gradient(top, #0462CD, #1E62D0);
    background-image: -o-linear-gradient(top, #0462CD, #1E62D0);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    color: #FFFFFF;
    font-family: Tahoma;
    font-size: 15px;
    padding: 20px;
    -webkit-box-shadow: 1px 1px 10px 0 #000000;
    -moz-box-shadow: 1px 1px 10px 0 #000000;
    box-shadow: 1px 1px 10px 0 #000000;
    text-shadow: 1px 1px 39px #000000;
    border: solid #000000 1px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    text-align: center;
 }
 
 .mybutton:hover {
    border: solid #337FED 1px;
    background: #1E62D0;
    background-image: -webkit-linear-gradient(top, #1E62D0, #3D94F6);
    background-image: -moz-linear-gradient(top, #1E62D0, #3D94F6);
    background-image: -ms-linear-gradient(top, #1E62D0, #3D94F6);
    background-image: -o-linear-gradient(top, #1E62D0, #3D94F6);
    background-image: -webkit-gradient(to bottom, #1E62D0, #3D94F6);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 80px;
    text-decoration: none;
 }